<template>
  <div class="shop container is-fullhd">
    <h1 class="title">Shop</h1>
    <section class="columns">
      <div class="column is-three-quarters">
        <h3 class="title is-5">Streak Freeze</h3>
        <div class="level">
          <div class="level-left">
            <p>Keep your streak if you forget to sketch for one day.</p>
          </div>
          <div class="level-right">
            <template v-if="user.hasStreakFreeze">
              <b-button disabled>Enabled</b-button>
            </template>
            <template v-else>
              <b-button @click.stop="buyStreakFreeze" :loading="submitting">
                <span class="icon is-large icon-coins mr-1">
                  <fa-icon :icon="['fas', 'coins']" />
                </span>
                10
              </b-button>
            </template>
          </div>
        </div>

        <h3 class="title is-5">Plus Membership</h3>
        <div class="level">
          <div class="level-left">
            <b-loading :is-full-page="false" v-model="loadingBilling" :can-cancel="false" />
            <template v-if="!loadingBilling">
              <template v-if="user.hasPlus">
                <template v-if="isPaid">
                  <p>
                    <!-- Thank you for supporting SketchDaily!
                    You can alway donate more if you wish.<br> -->
                    Thank you for supporting SketchDaily!<br>
                    You have <strong>{{formatPeriod()}}</strong> subscription.<br>
                    Your next payment will be taken on <strong>{{formatPaidUntil()}}</strong>.
                  </p>
                </template>
                <p v-else>
                  You have been granted Plus Membership for free. Must feel pretty special!
                  <br>
                  If you're feeling generous, please help keep SketchDaily
                  alive by supporting us on Patreon.
                </p>
              </template>
              <template v-else>
                <p>
                  Remove ads, download SVG versions of any sketch, get a pretty badge,<br>
                  and support SketchDaily all at the cost of a cup of coffee a month!
                </p>
              </template>
            </template>
          </div>

          <div class="level-right" v-if="billingData">
            <template v-if="user.hasPlus">
              <b-button v-if="isPaid" @click.stop="goToPortal" :loading="submitting">
                Manage Subscription
              </b-button>
              <a v-else
                href="https://www.patreon.com/ripexz"
                target="_blank"
                class="button is-patreon"
                rel="noopener">
                <span class="icon">
                  <fa-icon :icon="['fab', 'patreon']" />
                </span>
                <span>Patreon</span>
              </a>
            </template>
            <template v-else>
              <span class="mr-3">Subscribe for</span>
              <b-button @click.stop="buyPlus(billingData.pricing.monthly)" :loading="submitting">
                {{formatPrice(billingData.pricing.monthly)}}/month
              </b-button>
              <b-button @click.stop="buyPlus(billingData.pricing.annual)" :loading="submitting">
                {{formatPrice(billingData.pricing.annual)}}/year
              </b-button>
            </template>
          </div>
        </div>
      </div>

      <Sidebar class="column" />
    </section>
  </div>
</template>

<script>
import { loadStripe } from '@stripe/stripe-js';
import { mapGetters } from 'vuex';
import Sidebar from '@/components/Sidebar.vue';
import API from '@/services/api';
import dayjs from '@/services/dayjs';

export default {
  name: 'Shop',
  components: {
    Sidebar,
  },
  data() {
    return {
      submitting: false,

      billingData: null,
      loadingBilling: true,

      stripe: null,
    };
  },
  async created() {
    await this.getBilling();
    this.stripe = await loadStripe(this.billingData.stripeKey);
    this.loadingBilling = false;
    if (this.$route.query.id) {
      this.$gtag.event('purchase');
    }
  },
  computed: {
    ...mapGetters({
      user: 'session/user',
    }),
    isPaid() {
      return this.billingData && this.billingData.billing
        && this.billingData.billing.subscriptionId;
    },
  },
  methods: {
    async getBilling() {
      try {
        const res = await API.billing.get();
        this.billingData = res.data;
      } catch (e) {
        const error = API.handleError(e);
        if (error) this.$toasted.error(error.message, { duration: 2000 });
      }
    },
    async buyStreakFreeze() {
      if (this.submitting) return;
      this.submitting = true;
      try {
        await API.shop.buyStreakFreeze();
        await this.$store.dispatch('session/getProfile');
        this.$toasted.success('Streak Freeze purchased!', { duration: 2000 });
        this.$gtag.event('streak_freeze', { event_category: 'engagement' });
      } catch (e) {
        const error = API.handleError(e);
        if (error) this.$toasted.error(error.message, { duration: 2000 });
      }
      this.submitting = false;
    },
    async buyPlus(pricing) {
      if (this.submitting) return;
      this.submitting = true;
      try {
        const res = await API.billing.subscribe({ priceId: pricing.id });
        this.$gtag.event('begin_checkout');
        await this.stripe.redirectToCheckout({ sessionId: res.data.id });
      } catch (e) {
        const error = API.handleError(e);
        if (error) this.$toasted.error(error.message, { duration: 2000 });
      }
      this.submitting = false;
    },
    async goToPortal() {
      if (this.submitting) return;
      this.submitting = true;
      try {
        const res = await API.billing.portal();
        window.location.href = res.data.url;
      } catch (e) {
        const error = API.handleError(e);
        if (error) this.$toasted.error(error.message, { duration: 2000 });
        this.submitting = false;
      }
    },
    formatPrice(pricing) {
      return `€${pricing.price / 100}`;
    },
    formatPeriod() {
      if (this.billingData.billing.period === 'monthly') return 'a monthly';
      return 'an annual';
    },
    formatPaidUntil() {
      const pu = dayjs(this.billingData.billing.paidUntil);
      return pu.format('Do MMMM YYYY');
    },
  },
};
</script>

<style lang="scss" scoped>
.icon-coins {
  color: gold;
}
</style>
