<template>
  <div class="shop container is-fullhd">
    <h1 class="title">Shop</h1>
    <section class="columns">
      <div class="column is-three-quarters">
        <b-loading :is-full-page="false" v-model="checkingBilling" :can-cancel="false" />
        <template v-if="!checkingBilling">
          <h2 class="subtitle">Plus subscription is on!</h2>
          <p>Thank you for supporting SketchDaily! Taking you back to the shop...</p>
        </template>
      </div>

      <Sidebar class="column" />
    </section>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Sidebar from '@/components/Sidebar.vue';
import API from '@/services/api';
import dayjs from '@/services/dayjs';

export default {
  name: 'ShopSuccess',
  components: {
    Sidebar,
  },
  data() {
    return {
      checkingBilling: true,
    };
  },
  created() {
    this.checkBilling();
  },
  computed: {
    ...mapGetters({
      user: 'session/user',
    }),
  },
  methods: {
    async checkBilling() {
      let data = {};
      try {
        const res = await API.billing.get();
        data = res.data;
      } catch (e) {
        const error = API.handleError(e);
        if (error) this.$toasted.error(error.message, { duration: 2000 });
      }

      let retry = true;
      if (data.billing && data.billing.paidUntil) {
        const pu = dayjs(data.billing.paidUntil);
        if (pu.isAfter(dayjs())) {
          retry = false;
        }
      }

      if (retry) {
        setTimeout(() => {
          this.checkBilling();
        }, 1000);
      } else {
        await this.$store.dispatch('session/getProfile');
        this.checkingBilling = false;
        setTimeout(() => {
          this.$router.push('/shop');
        }, 3000);
      }
    },
  },
};
</script>
